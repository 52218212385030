<template>
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto pt-16 px-4 sm:px-6 lg:px-8">
      <h2 class="text-center text-3xl font-extrabold tracking-tight text-gray-900">
        Enrich your workflow in three easy steps
      </h2>
    </div>
  </div>

  <!-- Alternating Feature Sections -->
  <div class="relative pt-16 pb-32 overflow-hidden">
    <div aria-hidden="true" class="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
    <div class="relative">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div>
              <span class="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600">
                <InboxIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="mt-6">
              <div class="text-2xl font-bold tracking-tight text-gray-900">
                1. Claim your @BlockBase.dev email.
              </div>
              <p class="mb-4 text-lg text-gray-500">
                Pick a short address you can remember, or a long address nobody can guess.
              </p>
              <div class="text-2xl font-bold tracking-tight text-gray-900">
                2. Connect it to your Notion DB.
              </div>
              <p class="mb-4 text-lg text-gray-500">
                The integration only needs access to the Notion table you want mail to arrive in.
              </p>
              <div class="text-2xl font-bold tracking-tight text-gray-900">
                3. Setup an email filter forwarding to your @BlockBase.dev address.
              </div>
              <p class="mb-4 text-lg text-gray-500">
                Forward specific messages without granting us access to your Inbox.
              </p>
            </div>
          </div>
          <div class="mt-8 border-t border-gray-200 pt-2">
            <div class="mt-2">
              <a href="https://app.blockbase.dev/" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700">
                Get started
              </a>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0">
          <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="../assets/blockbase_in_action.png" alt="Blockbase in action" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  InboxIcon,
} from '@heroicons/vue/outline'

export default {
  components: {
    InboxIcon,
  },
  setup() {
    return {
    }
  },
}
</script>
