<template>
  <div class="bg-white">
    <Nav />
    <router-view/>
    <Foot />
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Foot from '@/components/Foot.vue'

export default {
  components: {
    Nav,
    Foot,
  }
}
</script>
<style>
</style>
