<template>
  <div class="bg-gradient-to-r from-purple-800 to-indigo-700">
    <div class="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
      <h2 class="text-3xl font-extrabold text-white tracking-tight">
        Integrate email into your Notion workflow
      </h2>
      <p class="mt-4 max-w-3xl text-lg text-purple-200">
        Notion.so is the heart of your knowledge base... don't let your email sit in a different silo!
      </p>
      <div class="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
        <div v-for="feature in features" :key="feature.name">
          <div>
            <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
              <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <div class="mt-6">
            <h3 class="text-lg font-medium text-white">{{ feature.name }}</h3>
            <p class="mt-2 text-base text-purple-200">
              {{ feature.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DocumentReportIcon,
  InboxIcon,
  TrashIcon,
  UsersIcon,
} from '@heroicons/vue/outline'

const features = [
  {
    name: 'Multiple Addresses',
    description: 'Setup one for invoices, a second for client emails, and another for newsletters. Pro users get unlimited addresses.',
    icon: InboxIcon,
  },
  {
    name: 'Data Safety',
    description: 'Built on AWS Lambda, we do not retain a copy of your email once it\'s in Notion.',
    icon: TrashIcon,
  },
  {
    name: 'Privacy 1st',
    description: 'Forward messages rather than granting access to your Inbox. Notion access is limited to your destination DBs.',
    icon: UsersIcon,
  },
  {
    name: 'Spam and Virus Free',
    description: 'Filtering provided by AWS Simple Email Service, with DKIM and SPF checking.',
    icon: DocumentReportIcon,
  },
]
export default {
  components: {
    DocumentReportIcon,
    InboxIcon,
    TrashIcon,
    UsersIcon,
  },
  setup() {
    return {
      features,
    }
  },
}
</script>
