<template>
  <footer class="bg-gray-50" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="grid grid-cols-2 gap-8 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                BlockBase
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in footerNavigation.solutions" :key="item.name">
                  <router-link :to="item.to" class="text-base text-gray-500 hover:text-gray-900">
                    {{ item.name }}
                  </router-link>
                </li>
                <li>
                  <a href="https://app.blockbase.dev" class="text-base text-gray-500 hover:text-gray-900">
                    Sign up
                  </a>
                </li>
                <li>
                  <a href="https://app.blockbase.dev" class="text-base text-gray-500 hover:text-gray-900">
                    Login
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Support
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in footerNavigation.support" :key="item.name">
                  <router-link :to="item.to" class="text-base text-gray-500 hover:text-gray-900">
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Company
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in footerNavigation.company" :key="item.name">
                  <router-link :to="item.to" class="text-base text-gray-500 hover:text-gray-900">
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Legal
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in footerNavigation.legal" :key="item.name">
                  <router-link :to="item.to" class="text-base text-gray-500 hover:text-gray-900">
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Newsletter />
      </div>
      <div class="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
        <div class="flex space-x-6 md:order-2">
          <a v-for="item in footerNavigation.social" :key="item.name" :href="item.to" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </a>
        </div>
        <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
          &copy; 2021 Produced by New Moon Labs and Ev Chapman
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import {
  InboxIcon,
  MenuIcon,
  SparklesIcon,
  XIcon,
} from '@heroicons/vue/outline'

import Newsletter from '@/components/Newsletter.vue'

const footerNavigation = {
  solutions: [
    { name: 'Free Blocks', to: '/blocks' },
  ],
  support: [
    { name: 'Pricing', to: '/pricing' },
    { name: 'Support', to: '/support' },
  ],
  company: [
    { name: 'Custom', to: '/custom' },
    { name: 'Contact', to: '/contact' },
  ],
  legal: [
    { name: 'Privacy', to: '/privacy' },
    { name: 'Terms', to: '/terms' },
  ],
  social: [
  ],
}

export default {
  data() {
    return {
      footerNavigation: footerNavigation,
    }
  },
  components: {
    Newsletter,
    InboxIcon,
    MenuIcon,
    SparklesIcon,
    XIcon,
  },
  methods: {
  },
}
</script>
