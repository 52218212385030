import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

import './index.css'

var app = createApp(App).use(store).use(router)

app.use(VueAxios, axios)

app.client = app.axios.create({
  withCredentials: true
  // baseURL: process.env.VUE_APP_API_URL,
  // timeout: 10000,
})

app.mount('#app')
