<template>
  <div class="mt-12 xl:mt-0">
    <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
      Subscribe to our newsletter
    </h3>
    <p class="mt-4 text-base text-gray-500">
      We'll keep you posted on updates and new features. Unsubscibe any time.
    </p>
    <form v-if="newsletterState === 'ready'" @submit="newsletterSignup" class="mt-4 sm:flex sm:max-w-md">
      <label for="emailAddress" class="sr-only">Email address</label>
      <input v-model="newsletterEmail" type="email" name="emailAddress" id="emailAddress" autocomplete="email" required="" class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400" placeholder="Enter your email" />
      <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button type="submit" class="w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700">
          Subscribe
        </button>
      </div>
    </form>
    <div v-else-if="newsletterState === 'busy'" class="mt-4 font-bold text-indigo-700">
      Submitting...
    </div>
    <div v-else class="mt-4 font-bold text-indigo-700">
      Thank you! We'll keep it to a minimum :)
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsletterEmail: '',
      newsletterState: 'ready',
    }
  },
  components: {
  },
  methods: {
    async newsletterSignup(e) {
      this.newsletterState = 'busy'
      e.preventDefault()
      const data = {
        form: "NewsletterSubscribe",
        from: this.newsletterEmail,
      }
      var response = {}
      try {
        response = await this.axios.post('https://forms-api.blockbase.dev/submit/blockbase', data)
      } catch (err) {
        console.log('TK: ERR!', err)
        return
      }
      if (!response.data.success) {
        console.log('TK: ERR!', response.data)
        return
      }
      this.newsletterState = 'done'
    }
  },
}
</script>
