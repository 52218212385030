import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'BlockBase.dev: Forward important email to Notion',
      description: 'Use Blockbase.dev to capture invoices, client emails, newsletters receipts, and more...  in your Notion DB.',
    }
  },
  {
    path: '/custom',
    name: 'Custom',
    component: () => import(/* webpackChunkName: "custom" */ '../views/Custom.vue'),
    meta: {
      title: 'BlockBase.dev: Contact us',
      description: 'Team up with BlockBase.dev for a custom Notion.so integration!'
    }
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing.vue'),
    meta: {
      title: 'BlockBase.dev: Pricing',
      description: 'Forward emails to Notion - get started for free!',
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: {
      title: 'BlockBase.dev: Terms of Service',
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    meta: {
      title: 'BlockBase.dev: Privacy Notice',
    }
  },
  {
    path: '/blocks',
    name: 'Blocks',
    component: () => import(/* webpackChunkName: "blocks" */ '../views/Blocks.vue'),
    meta: {
      title: 'BlockBase.dev: Free Blocks to enhance your Notion pages',
      description: 'Enhance your Notion pages with our free blocks: No login, zero tracking, team-oriented.'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    meta: {
      title: 'BlockBase.dev: Contact us',
      description: 'Contact the team at BlockBase.dev'
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue'),
    meta: {
      title: 'BlockBase.dev: Support',
      description: 'The BlockBase.dev team wantws you to succeed: we provide personal, hands-on support.'
    }
  },
  {
    path: '/help-work-in-progress',
    name: 'Help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
    meta: {
      title: 'BlockBase.dev: Support Center ',
      description: 'The BlockBase.dev team wantws you to succeed: we provide personal, hands-on help.'
    }
  },
]

var router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'BlockBase.dev';

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if (!to.meta.description) {
    to.meta.description = 'BlockBase.dev: enhancing your Notion workflow'
  }
  var tag = document.createElement('meta');
  tag.setAttribute('og:description', to.meta.description);
  tag.setAttribute('data-vue-router-controlled', '');
  document.head.appendChild(tag);
  tag = document.createElement('meta');
  tag.setAttribute('description', to.meta.description);
  tag.setAttribute('data-vue-router-controlled', '');
  document.head.appendChild(tag);
 
  window.scrollTo(0, 0);
  next();
});

export default router
