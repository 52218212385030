<template>

  <main>
    <Hero />
    <ThreeEasySteps  />
    <Features />
    <BottomCTA />
  </main>
</template>

<script>
import Hero from '@/components/Hero.vue'
import BottomCTA from '@/components/BottomCTA.vue'
import Features from '@/components/Features.vue'
//import Metrics from '@/components/Metrics.vue'
import ThreeEasySteps from '@/components/ThreeEasySteps.vue'

export default {
  components: {
    Hero,
    Features,
//    Metrics,
    ThreeEasySteps,
    BottomCTA,
  }
}
</script>
